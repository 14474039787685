//default member contact
function memberContact() {
  return {
    name: '',
    address: '',
    phone: '',
    mobile: '',
    remark: ''
  };
}

//default member contacts
function memberContacts() {
  return [memberContact(), memberContact(), memberContact()];
}

export default {
  memberContact,
  memberContacts
};
