<template>
  <div class="main-content">
    <div class="product-info-wrapper">
      <div class="content-block">
        <div class="container">
          <div
            class="product-item"
            v-for="(product, index) in products"
            :key="product.id"
          >
            <div class="productimg">
              <img :src="product.cover" alt="" />
            </div>
            <div class="product-info-wrap">
              <div class="product-info top">
                <div class="product-name">
                  {{ groupBuyingProducts[index].name }}
                </div>
                <div class="info-tools">
                  <div class="price"></div>
                  <div class="right-tool">
                    <div>
                      {{ groupBuyingProducts[index].spec_name }}
                    </div>
                    <div class="helper" style="visibility:hidden"></div>
                  </div>
                </div>
                <div class="info-tools">
                  <div class="price">
                    ${{ groupBuyingProducts[index].unit_price }}
                    <span>/{{ groupBuyingProducts[index].unit }}</span>
                  </div>
                  <div class="right-tool">
                    <GroupProductQtyInput
                      :product-index="index"
                      :quantity="product.quantity"
                      @quantity-change="changeProductQty"
                    ></GroupProductQtyInput>
                    <div
                      class="helper"
                      data-toggle="modal"
                      data-target="#modal-helper-change"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="total-area">
            <li item="小記">
              <span>$ {{ subtotal }}</span>
            </li>
            <li item="服務費" v-if="feeAmt !== null && feeAmt !== 0">
              <span>$ {{ feeAmt }}</span>
            </li>
            <li class="total" item="總計">
              <span v-if="remainingAmount != null">
                剩餘庫存:{{ remainingAmount }}
              </span>
              <span>${{ total }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 付款方式選擇 -->
      <PaymentBlock
        :methods="paymentMethods"
        :value="paymentMethod"
        @input="setPaymentMethod"
      ></PaymentBlock>

      <!--面交時間地點-->
      <PickupLocationBlock
        v-if="isPersonally"
        :options="pickupLocations"
        :value="pickupLocation"
        @input="setPickupLocation"
      ></PickupLocationBlock>

      <!--寄送-->
      <MemberContactBlock
        ref="member-contact-block"
        v-else-if="isShipping"
        @update-setting="setUpdateSetting"
        @set-member-contact="setMemberContact"
      ></MemberContactBlock>

      <div class="bottom-btn-block nofloat">
        <a
          v-if="showAddBookmark"
          class="btn-cta"
          @click.prevent="toggleFavorite"
        >
          {{ isAddedToFavorite ? '移除收藏' : '暫不訂購，加入收藏' }}
        </a>
        <a
          v-if="canFollow"
          class="btn-cta"
          @click.prevent="sendFollowGroupOrder"
        >
          {{ sendText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroupBuyingInfo } from '@/api/group-buying';
import { mapGetters, mapActions } from 'vuex';
import PaymentBlock from '@/components/FollowGroup/PaymentBlock';
import MemberContactBlock from '@/components/FollowGroup/MemberContactBlock';
import PickupLocationBlock from '@/components/FollowGroup/PickupLocationBlock';
import GroupProductQtyInput from '@/components/GroupProductQtyInput';
import { memberContact, memberContacts } from '@/utils/member-contacts';
import {
  followGroupBuying,
  getFollowedGroupDetail,
  updateMemberGroupBuyingOrder,
  toggleFavorite
} from '@/api/group-buying';
import { get } from 'lodash';
import LineLIFFApp from '@/mixins/line-liff-app';

export default {
  mixins: [LineLIFFApp],
  components: {
    PaymentBlock,
    MemberContactBlock,
    PickupLocationBlock,
    GroupProductQtyInput
  },
  data() {
    return {
      groupInfoLoaded: false, //是否已取得開團資訊
      groupInfo: null,

      //訂單相關資訊
      products: [],
      update_default_setting: false,
      paymentMethod: null,
      pickupLocation: null,
      memberContact: null,
      memberContacts: null,
      subtotal: 0,
      total: 0,
      totalItemsCount: 0,
      originTotalItemsCount: 0
    };
  },
  computed: {
    ...mapGetters(['userIsLogin']),
    groupId() {
      let groupId = this.$route.params.group_id;

      return groupId !== undefined
        ? this.$route.params.group_id
        : this.$route?.query?.groupId;
    },
    canFollow() {
      return this.groupActive;
    },
    groupActive() {
      return get(this.groupInfo, 'status') === 1;
    },
    beenFollowed() {
      return this.memberGroupBuyingOrderId !== null;
    },
    isEdit() {
      //編輯模式
      return this.beenFollowed;
    },
    memberGroupBuyingOrderId() {
      return get(this.groupInfo, 'member_group_buying_order_id');
    },
    groupBuyingProducts() {
      if (!this.groupInfoLoaded) {
        return [];
      }
      return this.groupInfo.group_buying_products;
    },
    feeAmt() {
      return this.groupInfoLoaded ? this.groupInfo.handling_fee : 0;
    },
    paymentMethods() {
      return this.groupInfoLoaded ? this.groupInfo.payment_methods : [];
    },
    pickupLocations() {
      return this.groupInfoLoaded ? this.groupInfo.pickup_locations : [];
    },
    isPersonally() {
      return this.groupInfoLoaded ? this.groupInfo.delivery_type === 1 : false;
    },
    isShipping() {
      return this.groupInfoLoaded ? this.groupInfo.delivery_type === 2 : false;
    },
    sendText() {
      return this.isEdit ? '送出修改' : '確認預定';
    },
    showAddBookmark() {
      return !this.beenFollowed;
    },
    isAddedToFavorite() {
      return get(this.groupInfo, 'is_favorited', false);
    },
    cover() {
      return get(this.groupInfo, 'cover');
    },
    remainingAmount() {
      if (
        this.groupInfo?.reach_amount === null ||
        this.groupInfo?.reach_amount === undefined
      ) {
        return null;
      }

      let amount =
        this.groupInfo.remaining_amount -
        (this.totalItemsCount - this.originTotalItemsCount);

      return amount >= 0 ? amount : 0;
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler(newVal) {
        if (newVal !== undefined) {
          this.fetchGroupInfo();
        }
      }
    },
    groupInfo: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.groupInfoLoaded = newVal !== null ? true : false;
      }
    },
    products: {
      immediate: true,
      deep: true,
      handler(newVal) {
        let subtotal = 0;
        let total = 0;
        let totalItemsCount = 0;
        let originTotalItemsCount = 0;

        newVal.forEach((element, index) => {
          subtotal +=
            element.quantity * this.groupBuyingProducts[index].unit_price;
          totalItemsCount += element.quantity;
          originTotalItemsCount += element.origin_actual_quantity;
        });
        total = subtotal + this.feeAmt;

        this.subtotal = subtotal;
        this.total = total;
        this.totalItemsCount = totalItemsCount;
        this.originTotalItemsCount = originTotalItemsCount;
      }
    }
  },
  methods: {
    ...mapActions('user', ['login', 'getInfo']),
    loginCallback() {
      this.fetchGroupInfo();
    },
    fetchGroupInfo() {
      getGroupBuyingInfo(this.groupId)
        .then(data => {
          this.groupInfo = data;
          let products = [];

          this.products = data.group_buying_products.map(data => {
            return {
              id: data.id,
              cover: data.cover,
              quantity: 0,
              origin_actual_quantity: 0
            };
          });

          if (this.isEdit && this.memberGroupBuyingOrderId !== null) {
            this.loadDefaultValue();
          }
        })
        .catch(() => {
          // this.lineLiffModeFlow();
        });
    },
    changeProductQty(product) {
      if (product.index !== null) {
        if (this.remainingAmount != null) {
          let plus = product.quantity - this.products[product.index].quantity;
          let afterCout = this.totalItemsCount + plus;

          if (afterCout > this.groupInfo.reach_amount) {
            let diff = afterCout - this.groupInfo.reach_amount;
            console.log(
              afterCout,
              product.quantity,
              this.products[product.index].quantity,
              diff
            );
            this.$set(
              this.products[product.index],
              'quantity',
              product.quantity
            );
            this.$set(
              this.products[product.index],
              'quantity',
              product.quantity - diff
            );
            return;
          }
        }
        this.$set(this.products[product.index], 'quantity', product.quantity);
      }
    },
    setPaymentMethod(method) {
      this.paymentMethod = method;
    },
    setPickupLocation(option) {
      this.pickupLocation = option;
    },
    setUpdateSetting(isUpdateSetting) {
      this.update_default_setting = isUpdateSetting;
    },
    setMemberContact(memberContact) {
      this.memberContact = memberContact;
    },
    getPostData() {
      let products = this.products.filter(product => product.quantity > 0);

      let postData = {
        delivery_type: this.groupInfo.delivery_type, //TODO: 應該api要改直接撈資料庫開團資料
        group_buying_order_id: this.groupId,
        products: products,
        payment_method_name: this.paymentMethod,
        update_default_setting: this.update_default_setting
      };

      if (this.groupInfo.delivery_type == 2) {
        postData.member_contact = this.memberContact;
      } else if (this.groupInfo.delivery_type == 1) {
        postData.pickup_location = this.pickupLocation;
      }

      return postData;
    },
    sendFollowGroupOrder() {
      if (this.isEdit === true) {
        this.updateMemberGroupBuyingOrder();
      } else {
        this.followGroupBuying();
      }
    },
    done(data) {
      if (this.update_default_setting) {
        this.$refs['member-contact-block'].updateSettings();
      }

      let postData = this.getPostData();

      let products = postData.products;

      let url =
        products.length > 0
          ? {
              name: 'FollowedList',
              params: {
                group_id: this.groupId
              }
            }
          : {
              name: 'FollowGroup',
              params: {
                group_id: this.groupId
              }
            };
      this.$router.push(url);
    },
    loadDefaultValue() {
      if (this.memberGroupBuyingOrderId === null) {
        return;
      }
      getFollowedGroupDetail(this.memberGroupBuyingOrderId).then(data => {
        let orderDetails = data.group_buying_order_details;

        for (let groupBuyingProduct of this.products) {
          let quantity = 0;
          let origin_actual_quantity = 0;

          let groupBuyingProductId = groupBuyingProduct.id;

          let groupBuyingOrderDetail = orderDetails.find(item => {
            return item.group_buying_product_id == groupBuyingProductId;
          });

          if (groupBuyingOrderDetail) {
            quantity = groupBuyingOrderDetail.quantity;
            origin_actual_quantity =
              groupBuyingOrderDetail.origin_actual_quantity;
          }

          groupBuyingProduct.quantity = quantity;
          groupBuyingProduct.origin_actual_quantity = quantity;
        }
        //確認有沒有抓取成功

        this.paymentMethod = data.payment_method_name;
        this.memberContact = data.member_contact;
        this.pickupLocation = get(data, 'pickup_location.id');
      });
    },
    followGroupBuying() {
      followGroupBuying(this.getPostData()).then(data => {
        this.done(data);
      });
    },
    updateMemberGroupBuyingOrder() {
      updateMemberGroupBuyingOrder({
        member_group_buying_order_id: this.memberGroupBuyingOrderId,
        ...this.getPostData()
      }).then(data => {
        this.done(data);
      });
    },
    toggleFavorite() {
      toggleFavorite(this.groupId).then(data => {
        this.$set(this.groupInfo, 'is_favorited', data.is_favorited);
      });
    }
  }
};
</script>
