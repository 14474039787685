<template>
  <div class="content-block hidden-block full active" id="f2f">
    <h2 class="lg-title">
      <div class="title-word">
        領取方式
        <span>面交</span>
      </div>
    </h2>
    <div class="radio-select-block">
      <div
        class="radio-select"
        v-for="(option, index) in options"
        :key="`f2f-options-${index}`"
      >
        <input
          :id="`loc0${index}`"
          type="radio"
          name="location"
          :value="option.id"
          :checked="value == option.id"
          @input="$emit('input', option.id)"
        />
        <label :for="`loc0${index}`">
          <cr></cr>
          {{ option.time_from }}-{{ option.time_to }} {{ option.location }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      required: true,
      type: Array
    },
    value: {
      required: false,
      type: [String, Number]
    }
  }
};
</script>
