<template>
  <div class="content-block full" id="transfer">
    <h2 class="lg-title">
      <div class="title-word">
        領取方式
        <span>寄送</span>
      </div>
    </h2>
    <ul class="address-tab" role="tablist">
      <li
        :class="{ active: index == 0 }"
        role="presentation"
        v-for="(tab, index) in taketabs"
        :key="`tabk${index}`"
      >
        <a
          :href="`#tab0${index + 1}`"
          aria-controls="home"
          role="tab"
          data-toggle="tab"
          :data-tab-index="index"
          @click="setMemberContact"
        >
          {{ tab }}
        </a>
      </li>
    </ul>
    <div class="save-tool">
      <div class="check-wrap">
        <input
          id="updateDefault"
          type="checkbox"
          name="save"
          v-model="update_default_setting"
        />
        <label for="updateDefault">更新預設跟團資料</label>
      </div>
    </div>
    <div class="tab-content-wrapper">
      <div
        v-for="(setting, index) in addressList"
        :key="index"
        class="tab-content tab-pane fade in"
        :class="{ active: index == activeTabIndex }"
        :id="`tab0${index + 1}`"
        role="tabpanel"
      >
        <div class="input-block">
          <div class="input-wrap">
            <input
              type="text"
              :placeholder="`請輸入收件人名稱${index + 1}`"
              v-model="setting.name"
            />
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="請輸入地址"
              v-model="setting.address"
            />
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="請輸入市話"
              v-model="setting.phone"
            />
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="請輸入手機1"
              v-model="setting.mobile"
            />
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="請輸入手機2"
              v-model="setting.mobile2"
            />
          </div>
          <div class="input-wrap">
            <textarea
              placeholder="請輸入備註"
              v-model="setting.remark"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <MessageAlert
      :show="showModal"
      @close-modal="confirmMsg"
      :msgTitle="msgTitle"
      :msgContent="msgContent"
    ></MessageAlert>
  </div>
</template>

<script>
import memberSettingsMixin from '@/mixins/member-settings';
import FollowSettingMixin from '@/mixins/follow-setting';
import MessageAlert from '@/components/Modals/ModalMessageAlert';

export default {
  mixins: [memberSettingsMixin, FollowSettingMixin],
  components: {
    MessageAlert
  },
  data() {
    return {
      activeTabIndex: 0,
      update_default_setting: true
    };
  },
  computed: {
    memberContact() {
      return this.addressList.length > 0
        ? this.addressList[this.activeTabIndex]
        : this.getDefaultSetting()[this.activeTabIndex];
    }
  },
  watch: {
    update_default_setting: {
      immediate: true,
      handler(newVal) {
        this.$emit('update-setting', newVal);
      }
    },
    memberContact: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit('set-member-contact', val);
      }
    }
  },
  methods: {
    setMemberContact(e) {
      this.activeTabIndex = parseInt(e.target.dataset.tabIndex);
    }
  }
};
</script>
