<template>
  <div class="content-block">
    <h2 class="lg-title">付款方式</h2>
    <div class="input-tab-block">
      <div
        class="radio-block"
        v-for="(method, index) in methods"
        :key="`method-${method.name}`"
      >
        <input
          :id="`me0${index}`"
          type="radio"
          name="delivery"
          :value="method.name"
          :checked="value === method.name"
          @input="$emit('input', method.name)"
        />
        <label :for="`me0${index}`">
          {{ method.name === 'ECPay' ? '線上支付' : method.description }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    methods: {
      required: true,
      type: Array
    },
    value: {
      required: false,
      type: String
    }
  },
  watch: {
    methods: {
      immediate: false,
      handler(val) {
        if (val.length > 0 && this.value === null) {
          this.$emit('input', val[0].name);
        }
      }
    }
  }
};
</script>
